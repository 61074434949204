<template>
  <section class="end-customer">
    <!-- title -->
    <h1 class="end-customer__title page__title">Конечный заказчик</h1>
    <!-- content -->
    <div
      class="end-customer-content page-wrapper"
      v-if="
        user &&
        user.access_group !== 'manager' &&
        user.access_group !== 'director'
      "
    >
      <div class="end-customer-fields">
        <div class="end-customer-fields-row fields-row">
          <base-input
            :id="'end-customer-customer'"
            :label="'Конечный заказчик'"
            :required="true"
            v-model="data.name"
          />
        </div>
        <div class="end-customer-fields-row fields-row _inn">
          <base-input
            :label="'ИНН'"
            :id="'end-customer-inn'"
            type="number"
            :message="message"
            @input="message = ''"
            :required="true"
            v-model="data.inn"
          />
        </div>
        <div class="end-customer-fields-row fields-row">
          <base-input
            :label="'Регион'"
            :id="'end-customer-region'"
            :required="true"
            v-model="data.region"
          />
        </div>
        <div class="end-customer-fields-row fields-row _city">
          <base-input
            :label="'Город'"
            :id="'end-customer-city'"
            :required="true"
            v-model="data.city"
          />
        </div>
        <div class="end-customer-fields-row fields-row _bio">
          <base-input
            :label="'ФИО заведующего эндоскопии'"
            :id="'end-customer'"
            :placeholder="'Если есть'"
            :labelStyle="{ transform: `translateY(10px)` }"
            :required="true"
            v-model="data.head_of_endoscopy_full_name"
          />
        </div>
      </div>
    </div>
    <!-- buttons -->
    <div
      class="end-customer-buttons page-buttons _save"
      v-if="
        user &&
        user.access_group !== 'manager' &&
        user.access_group !== 'director'
      "
    >
      <div class="end-customer-buttons__send _save__button">
        <base-button type="filled" @clicked="addEndCustomer"
          >Сохранить</base-button
        >
      </div>
    </div>
    <!-- search -->
    <div class="end-customer-search page-wrapper" v-if="!edit">
      <div class="end-customer-search-field">
        <base-input
          :id="'endCustomerSearch'"
          :placeholder="!$isMobile ? 'Поиск по контрагенту или ИНН' : 'Поиск'"
          :search="true"
          :searchIcon="true"
          :suggestionWidth="380"
          :suggestions="suggestions"
          @setSuggestion="getCurrent($event.value)"
          @keypressEnter="getEndCustomers()"
          @clear="(query = ''), (suggestions = []), getEndCustomers()"
          @input="getEndCustomers('search')"
          v-model="query"
        />
      </div>
    </div>
    <!-- table -->
    <div class="page-wrapper" v-if="!edit">
      <end-customer-table
        :endCustomers="endCustomers"
        @edit="editEndCustomer"
      />
    </div>
  </section>
  <base-modal
    :open="openModal"
    :text="'Сохранено'"
    @close="openModal = false"
  />
</template>

<script>
import { mapState } from "vuex";
import { useMeta } from "vue-meta";
import EndCustomerTable from "@/components/endCustomer/EndCustomerTable";

export default {
  name: "EndCustomer",
  components: {
    EndCustomerTable,
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      data: {
        name: "",
        inn: "",
        region: "",
        city: "",
        head_of_endoscopy_full_name: "",
      },
      message: "",
      query: "",
      openModal: false,
      edit: false,
      id: "",
      endCustomers: [],
      suggestions: [],
    };
  },
  setup() {
    useMeta({ title: "Конечный заказчик" });
  },
  mounted() {
    this.getEndCustomers();
  },
  methods: {
    getEndCustomers(type = "") {
      !type && this.$store.dispatch("loading", true);
      const params = {
        [this.query && "search"]: this.query,
      };
      this.$api
        .get(`${this.$api.defaults.API}/end-customer`, { params })
        .then((res) => {
          this.suggestions = [];
          if (type !== "search") this.endCustomers = [];
          for (let c of res.data.results) {
            if (type !== "search") this.endCustomers.push(c);
            else {
              // get search suggestions
              if (this.query) {
                this.suggestions.push({
                  key: c.name,
                  value: c.id,
                });
              }
            }
          }
          this.$store.dispatch("loading", false);
        })
        .catch((err) => {
          new Error(err);
          this.$store.dispatch("loading", false);
        });
    },
    getCurrent(id) {
      this.$store.dispatch("loading", true);
      this.$api
        .get(`${this.$api.defaults.API}/end-customer/${id}`)
        .then((res) => {
          this.endCustomers = [];
          this.endCustomers.push(res.data);
          this.$store.dispatch("loading", false);
        })
        .catch((err) => {
          new Error(err);
          this.suggestions = [];
          this.endCustomers = [];
          this.$store.dispatch("loading", false);
        });
    },
    addEndCustomer() {
      if (!Object.values(this.data).some((i) => !i)) {
        this.$store.dispatch("loading", true);
        // edit
        if (this.edit) {
          this.$api
            .patch(
              `${this.$api.defaults.API}/end-customer/${this.id}`,
              this.data
            )
            .then((res) => {
              const index = this.endCustomers.findIndex(
                (i) => i.id == res.data.id
              );
              this.endCustomers[index] = res.data;
              for (const [key] of Object.entries(this.data)) {
                this.data[key] = "";
              }
              this.$store.dispatch("loading", false);
              this.edit = false;
              this.openModal = true;
            })
            .catch((err) => {
              new Error(err);
              this.$store.dispatch("loading", false);
            });
        }
        // add
        else {
          this.$api
            .post(`${this.$api.defaults.API}/end-customer`, this.data)
            .then((res) => {
              this.endCustomers.push(res.data);
              for (const [key] of Object.entries(this.data)) {
                this.data[key] = "";
              }
              this.$store.dispatch("loading", false);
              this.openModal = true;
            })
            .catch((err) => {
              new Error(err);
              this.$store.dispatch("loading", false);
              if (err?.response?.data?.inn)
                this.message = "Данный ИНН уже существует в нашей базе данных";
            });
        }
      }
    },
    editEndCustomer(val) {
      const endCustomer = this.endCustomers.find((i) => i.id === val);
      this.id = endCustomer.id;
      this.edit = true;
      for (const [key] of Object.entries(endCustomer)) {
        this.data[key] = endCustomer[key];
      }
    },
  },
};
</script>
