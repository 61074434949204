<template>
  <section class="requisites">
    <!-- title -->
    <h1 class="requisites__title page__title">Реквизиты компании</h1>
    <!-- content -->
    <div
      class="requisites-content page-wrapper"
      v-if="
        user &&
        user.access_group !== 'manager' &&
        user.access_group !== 'director'
      "
    >
      <div class="requisites-fields">
        <div class="requisites-fields-row fields-row">
          <base-input
            :id="'requisitesCompany'"
            :label="'Компания'"
            :required="true"
            v-model="data.name"
          />
        </div>
        <div class="requisites-fields-row fields-row _inn">
          <base-input
            :id="'requisitesInn'"
            :label="'ИНН'"
            type="number"
            :message="message"
            @input="message = ''"
            :required="true"
            v-model="data.inn"
          />
        </div>
        <div class="requisites-fields-row fields-row">
          <base-input
            :id="'requisitesAddress'"
            :label="'Адрес'"
            :required="true"
            v-model="data.address_street"
          />
        </div>
        <div class="requisites-fields-row fields-row">
          <base-input
            :id="'requisitesCity'"
            :label="'Город'"
            v-model="data.address_city"
          />
        </div>
        <div class="requisites-fields-row fields-row">
          <base-input
            :id="'requisitesCountry'"
            :label="'Страна'"
            :required="true"
            v-model="data.address_country"
          />
        </div>
        <div class="requisites-fields-row fields-row _index">
          <base-input
            :id="'requisitesIndex'"
            :label="'Индекс'"
            type="number"
            :short="true"
            :required="true"
            v-model="data.address_index"
          />
        </div>
        <div class="requisites-fields-row fields-row">
          <base-input
            :label="'Телефон'"
            type="number"
            :id="'settingsPhone'"
            :maxLength="18"
            :required="true"
            v-model="data.phone_number"
          />
        </div>
        <div class="requisites-fields-row fields-row _email">
          <base-input
            :id="'requisitesEmail'"
            type="email"
            :label="'Электронная почта'"
            :required="true"
            v-model="data.email"
          />
        </div>
        <div class="requisites-fields-row fields-row">
          <base-input
            :id="'requisitesBio'"
            :label="'ФИО'"
            v-model="data.director_full_name"
          />
        </div>
        <div class="requisites-fields-row fields-row _position">
          <base-input
            :id="'requisitesPosition'"
            :label="'Должность'"
            :required="true"
            v-model="data.director_position"
          />
        </div>
        <div class="requisites-file _logo">
          <span class="requisites-file__title">Логотип</span>
          <div
            class="requisites-file-field"
            v-if="!logo_preview"
            @dragover.prevent
            @drop="drop('logo', 'requisitesLogo')"
          >
            <label for="requisitesLogo">
              {{ !$isMobile ? "Перетащите или " : "" }}
              <span>{{ !$isMobile ? "выберите" : "Выберите файл" }}</span>
              {{ !$isMobile ? "файл" : "" }}
              <input
                type="file"
                id="requisitesLogo"
                ref="requisitesLogo"
                multiple
                @change="uploadImage('logo', 'requisitesLogo')"
                accept=".jpg,.jpeg,.png"
              />
            </label>
          </div>
          <div class="requisites-file-loaded" v-else>
            <div
              class="requisites-file-loaded__close"
              @click="(logo = null), (logo_preview = null)"
            >
              <img src="@/assets/icons/image-remove-icon.svg" alt="Remove" />
            </div>
            <img :src="logo_preview" alt="Logo" />
          </div>
        </div>
        <div class="requisites-file">
          <span class="requisites-file__title">Оттиск печати</span>
          <div
            class="requisites-file-field"
            v-if="!blank_photo_preview"
            @dragover.prevent
            @drop.prevent="drop('blank_photo', 'requisitesBlankPhoto')"
          >
            <label for="requisitesBlankPhoto">
              {{ !$isMobile ? "Перетащите или " : "" }}
              <span>{{ !$isMobile ? "выберите" : "Выберите файл" }}</span>
              {{ !$isMobile ? "файл" : "" }}
              <input
                type="file"
                id="requisitesBlankPhoto"
                ref="requisitesBlankPhoto"
                multiple
                @change="uploadImage('blank_photo', 'requisitesBlankPhoto')"
                accept=".pdf,.jpg,.jpeg,.png"
              />
            </label>
          </div>
          <div class="requisites-file-loaded" v-else>
            <div
              class="requisites-file-loaded__close"
              @click="(blank_photo = null), (blank_photo_preview = null)"
            >
              <img src="@/assets/icons/image-remove-icon.svg" alt="Remove" />
            </div>
            <img :src="blank_photo_preview" alt="BlankPhoto" />
          </div>
        </div>
      </div>
    </div>
    <!-- buttons -->
    <div
      class="requisites-buttons page-buttons _save"
      v-if="
        user &&
        user.access_group !== 'manager' &&
        user.access_group !== 'director'
      "
    >
      <div class="requisites__send _save__button">
        <base-button :type="'filled'" @click="addCompany">
          Сохранить
        </base-button>
      </div>
    </div>
    <!-- search -->
    <div class="requisites-search page-wrapper" v-if="!edit">
      <div class="requisites-search-field">
        <base-input
          :id="'requisitesSearch'"
          :placeholder="!$isMobile ? 'Поиск по контрагенту или ИНН' : 'Поиск'"
          :search="true"
          :searchIcon="true"
          :suggestionWidth="380"
          :suggestions="suggestions"
          @setSuggestion="getCurrent($event.value)"
          @keypressEnter="getCompanies()"
          @clear="(query = ''), (suggestions = []), getCompanies()"
          @input="getCompanies('search')"
          v-model="query"
        />
      </div>
    </div>
    <!-- table -->
    <div class="page-wrapper" v-if="!edit">
      <company-requisites-table :companies="companies" @edit="editCompany" />
    </div>
  </section>
  <base-modal
    :open="openModal"
    :text="'Сохранено'"
    @close="openModal = false"
  />
</template>

<script>
import { mapState } from "vuex";
import { useMeta } from "vue-meta";
import CompanyRequisitesTable from "@/components/companyRequisites/CompanyRequisitesTable.vue";

export default {
  name: "CompanyRequisites",
  components: {
    CompanyRequisitesTable,
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      data: {
        name: "",
        inn: null,
        address_street: "",
        address_city: "",
        address_country: "",
        address_index: null,
        phone_number: null,
        email: "",
        director_full_name: "",
        director_position: "",
      },
      logo: null,
      blank_photo: null,
      logo_preview: null,
      blank_photo_preview: null,
      message: "",
      query: "",
      openModal: false,
      edit: false,
      id: "",
      companies: [],
      suggestions: [],
    };
  },
  setup() {
    useMeta({ title: "Реквизиты компании" });
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    getCompanies(type) {
      !type && this.$store.dispatch("loading", true);
      const params = {
        [this.query && "search"]: this.query,
      };
      this.$api
        .get(`${this.$api.defaults.API}/company`, { params })
        .then((res) => {
          this.suggestions = [];
          if (type !== "search") this.companies = [];
          for (let c of res.data.results) {
            if (type !== "search") this.companies.push(c);
            // get search suggestions
            else {
              if (this.query) {
                this.suggestions.push({
                  key: c.name,
                  value: c.id,
                });
              }
            }
          }
          this.$store.dispatch("loading", false);
        })
        .catch((err) => {
          new Error(err);
          this.$store.dispatch("loading", false);
        });
    },
    getCurrent(id) {
      this.$store.dispatch("loading", true);
      this.$api
        .get(`${this.$api.defaults.API}/company/${id}`)
        .then((res) => {
          this.companies = [];
          this.companies.push(res.data);
          this.$store.dispatch("loading", false);
        })
        .catch((err) => {
          new Error(err);
          this.suggestions = [];
          this.companies = [];
          this.$store.dispatch("loading", false);
        });
    },
    addCompany() {
      if (!Object.values(this.data).some((i) => !i)) {
        this.$store.dispatch("loading", true);

        let formData = new FormData();

        for (const [key, value] of Object.entries(this.data)) {
          formData.append(key, value);
        }
        // if logo or blank_photo
        this.logo && formData.append("logo", this.logo);
        this.blank_photo && formData.append("blank_photo", this.blank_photo);

        // edit
        if (this.edit) {
          this.$api
            .patch(`${this.$api.defaults.API}/company/${this.id}`, formData)
            .then((res) => {
              const index = this.companies.findIndex(
                (i) => i.id == res.data.id
              );
              this.companies[index] = res.data;
              for (const [key] of Object.entries(this.data)) {
                this.data[key] = "";
              }
              this.$store.dispatch("loading", false);
              this.logo_preview = null;
              this.blank_photo_preview = null;
              this.edit = false;
              this.openModal = true;
              this.message = "";
            })
            .catch((err) => {
              new Error(err);
              this.$store.dispatch("loading", false);
            });
        }
        // add
        else {
          this.$api
            .post(`${this.$api.defaults.API}/company`, formData)
            .then((res) => {
              this.companies.push(res.data);
              for (const [key] of Object.entries(this.data)) {
                this.data[key] = "";
              }
              this.$store.dispatch("loading", false);
              this.logo_preview = null;
              this.blank_photo_preview = null;
              this.openModal = true;
              this.message = "";
            })
            .catch((err) => {
              new Error(err);
              this.$store.dispatch("loading", false);
              if (
                err?.response?.data?.inn &&
                err?.response?.data?.inn.includes("already exists")
              )
                this.message = "Данный ИНН уже существует в нашей базе данных";
            });
        }
      }
    },
    uploadImage(type, field) {
      const file = this.$refs[field].files[0];
      this.data[type] = file;
      this[`${type}_preview`] = URL.createObjectURL(file);
    },
    drop(type, field) {
      const e = window.event;
      e.preventDefault();
      this.$refs[field].files = e.dataTransfer.files;
      this.uploadImage(type, field);
    },
    editCompany(val) {
      const company = this.companies.find((i) => i.id === val);
      this.id = company.id;
      this.edit = true;
      for (const [key] of Object.entries(company)) {
        if (key !== "logo" && key !== "blank_photo")
          this.data[key] = company[key];
      }
      this.logo_preview = company.logo;
      this.blank_photo_preview = company.blank_photo;
    },
  },
};
</script>
