<template>
  <table class="counterparty-table" v-if="!$isMobile">
    <thead>
      <tr>
        <th>Контрагент</th>
        <th class="upp">Инн</th>
        <th>Адрес</th>
        <th>Телефон</th>
        <th>Эл. почта</th>
        <th>Контактное лицо</th>
        <th>Скидка</th>
      </tr>
    </thead>
    <tbody v-if="counterparties.length">
      <tr
        v-for="c in counterparties"
        :key="c.id"
        @click="
          user &&
            user.access_group !== 'manager' &&
            user.access_group !== 'director' &&
            $emit('edit', c.id)
        "
      >
        <td>
          <span>
            {{ c.name }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ c.name }}
          </span>
        </td>
        <td>
          <span>
            {{ c.inn }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ c.inn }}
          </span>
        </td>
        <td>
          <span>
            {{ c.address_street && c.address_street + "," }}
            {{ c.address_city && c.address_city + "," }}
            {{ c.address_country && c.address_country + "," }}
            {{ c.address_index && c.address_index }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ c.address_street && c.address_street + "," }}
            {{ c.address_city && c.address_city + "," }}
            {{ c.address_country && c.address_country + "," }}
            {{ c.address_index && c.address_index }}
          </span>
        </td>
        <td>
          <span> +{{ c.phone_number }} </span>
          <span class="tooltip" v-tooltip> +{{ c.phone_number }} </span>
        </td>
        <td>
          <span>
            {{ c.email }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ c.email }}
          </span>
        </td>
        <td>
          <span>
            {{ c.contact_person }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ c.contact_person }}
          </span>
        </td>
        <td class="no-wrap">{{ c.discount }}%</td>
      </tr>
    </tbody>
  </table>
  <div
    class="counterparty-table-mobile table-mobile"
    ref="table"
    v-else-if="counterparties.length && $isMobile"
  >
    <div
      class="table-mobile-column"
      v-for="c in counterparties"
      :key="c.id"
      @click="
        user &&
          user.access_group !== 'manager' &&
          user.access_group !== 'director' &&
          $emit('edit', c.id)
      "
    >
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Контрагент </span>
        <span class="table-mobile__value">
          {{ c.name }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title upp"> Инн </span>
        <span class="table-mobile__value">
          {{ c.inn }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Адрес </span>
        <span class="table-mobile__value">
          {{ c.address_street && c.address_street + "," }}
          {{ c.address_city && c.address_city + "," }}
          {{ c.address_country && c.address_country + "," }}
          {{ c.address_index && c.address_index }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Телефон </span>
        <span class="table-mobile__value"> +{{ c.phone_number }} </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Эл. почта </span>
        <span class="table-mobile__value">
          {{ c.email }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Конт. лицо </span>
        <span class="table-mobile__value">
          {{ c.contact_person }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Скидка </span>
        <span class="table-mobile__value"> {{ c.discount }}% </span>
      </div>
    </div>
  </div>
  <span
    class="offer-content-notFound content-notFound"
    v-if="!counterparties.length"
  >
    Ничего не найдено
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CounterpartyTable",
  props: {
    counterparties: {
      type: Array,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  emits: ["edit"],
};
</script>
