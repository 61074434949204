<template>
  <section class="counterparty">
    <!-- title -->
    <h1 class="counterparty__title page__title">Контрагент</h1>
    <!-- content and fields -->
    <div
      class="counterparty-content page-wrapper"
      v-if="
        user &&
        user.access_group !== 'manager' &&
        user.access_group !== 'director'
      "
    >
      <div class="counterparty-fields">
        <div class="counterparty-fields-row fields-row">
          <base-input
            :label="'Контрагент'"
            :id="'counterparty'"
            :required="true"
            v-model="data.name"
          />
        </div>
        <div class="counterparty-fields-row fields-row _inn">
          <base-input
            :label="'ИНН'"
            type="number"
            :id="'counterpartyInn'"
            :message="message"
            @input="message = ''"
            :required="true"
            v-model="data.inn"
          />
        </div>
        <div class="counterparty-fields-row fields-row _address">
          <base-input
            :label="'Адрес'"
            :id="'counterpartyAddress'"
            :required="true"
            v-model="data.address_street"
          />
        </div>
        <div class="counterparty-fields-row fields-row">
          <base-input
            :label="'Город'"
            :id="'counterpartyCity'"
            :required="true"
            v-model="data.address_city"
          />
        </div>
        <div class="counterparty-fields-row fields-row">
          <base-input
            :label="'Страна'"
            :id="'counterpartyCountry'"
            :required="true"
            v-model="data.address_country"
          />
        </div>
        <div class="counterparty-fields-row fields-row _index">
          <base-input
            :label="'Индекс'"
            type="number"
            :short="true"
            :id="'counterpartyIndex'"
            :required="true"
            v-model="data.address_index"
          />
        </div>
        <div class="counterparty-fields-row fields-row">
          <base-input
            :label="'Телефон'"
            type="number"
            :maxLength="18"
            :id="'counterpartyPhone'"
            :required="true"
            v-model="data.phone_number"
          />
        </div>
        <div class="counterparty-fields-row fields-row">
          <base-input
            :label="'Электронная почта'"
            input-type="email"
            :id="'counterpartyEmail'"
            :required="true"
            v-model="data.email"
          />
        </div>
        <div class="counterparty-fields-row fields-row _contact-person">
          <base-input
            :label="'Контактное лицо'"
            :id="'counterpartyContactPerson'"
            :required="true"
            v-model="data.contact_person"
          />
        </div>
        <div class="counterparty-fields-row fields-row">
          <base-input
            :label="'Скидка'"
            type="number"
            :short="true"
            :id="'counterpartyDiscount'"
            :required="true"
            :maxLength="3"
            v-model="data.discount"
          />
        </div>
      </div>
    </div>
    <!-- buttons -->
    <div
      class="counterparty-buttons page-buttons _save"
      v-if="
        user &&
        user.access_group !== 'manager' &&
        user.access_group !== 'director'
      "
    >
      <div class="counterparty__send _save__button">
        <base-button type="filled" @click="addCounterparty">
          Сохранить
        </base-button>
      </div>
    </div>
    <!-- search -->
    <div class="counterparty-search page-wrapper" v-if="!edit">
      <div class="counterparty-search-field">
        <base-input
          :id="'counterpartySearch'"
          :placeholder="!$isMobile ? 'Поиск по контрагенту или ИНН' : 'Поиск'"
          :search="true"
          :searchIcon="true"
          :suggestionWidth="380"
          :suggestions="suggestions"
          @setSuggestion="getCurrent($event.value)"
          @keypressEnter="getCounterparties()"
          @clear="(query = ''), (suggestions = []), getCounterparties()"
          @input="getCounterparties('search')"
          v-model="query"
        />
      </div>
    </div>
    <!-- table -->
    <div class="page-wrapper" v-if="!edit">
      <counterparty-table
        :counterparties="counterparties"
        @edit="editCounterparty"
      />
    </div>
  </section>
  <base-modal
    :open="openModal"
    :text="'Сохранено'"
    @close="openModal = false"
  />
</template>

<script>
import { mapState } from "vuex";
import { useMeta } from "vue-meta";
import CounterpartyTable from "@/components/counterparty/CounterpartyTable.vue";

export default {
  name: "Counterparty",
  components: {
    CounterpartyTable,
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      data: {
        name: "",
        inn: "",
        address_street: "",
        address_city: "",
        address_country: "",
        address_index: "",
        phone_number: "",
        email: "",
        contact_person: "",
        discount: "",
      },
      message: "",
      query: "",
      openModal: false,
      edit: false,
      id: "",
      counterparties: [],
      suggestions: [],
    };
  },
  setup() {
    useMeta({ title: "Контрагент" });
  },
  mounted() {
    this.getCounterparties();
  },
  methods: {
    getCounterparties(type = "") {
      !type && this.$store.dispatch("loading", true);
      const params = {
        [this.query && "search"]: this.query,
      };
      this.$api
        .get(`${this.$api.defaults.API}/counterparty`, { params })
        .then((res) => {
          this.suggestions = [];
          if (type !== "search") this.counterparties = [];
          for (let c of res.data.results) {
            if (type !== "search") this.counterparties.push(c);
            else {
              // get search suggestions
              if (this.query) {
                this.suggestions.push({
                  key: c.name,
                  value: c.id,
                });
              }
            }
          }
          this.$store.dispatch("loading", false);
        })
        .catch((err) => {
          new Error(err);
          this.$store.dispatch("loading", false);
        });
    },
    getCurrent(id) {
      this.$store.dispatch("loading", true);
      this.$api
        .get(`${this.$api.defaults.API}/counterparty/${id}`)
        .then((res) => {
          this.counterparties = [];
          this.counterparties.push(res.data);
          this.$store.dispatch("loading", false);
        })
        .catch((err) => {
          new Error(err);
          this.suggestions = [];
          this.counterparties = [];
          this.$store.dispatch("loading", false);
        });
    },
    addCounterparty() {
      if (!Object.values(this.data).some((i) => !i)) {
        this.$store.dispatch("loading", true);
        // edit
        if (this.edit) {
          this.$api
            .patch(
              `${this.$api.defaults.API}/counterparty/${this.id}`,
              this.data
            )
            .then((res) => {
              const index = this.counterparties.findIndex(
                (i) => i.id == res.data.id
              );
              this.counterparties[index] = res.data;
              for (const [key] of Object.entries(this.data)) {
                this.data[key] = "";
              }
              this.edit = false;
              this.openModal = true;
              this.$store.dispatch("loading", false);
            })
            .catch((err) => {
              new Error(err);
              this.$store.dispatch("loading", false);
            });
        }
        // add
        else {
          this.$api
            .post(`${this.$api.defaults.API}/counterparty`, this.data)
            .then((res) => {
              this.counterparties.push(res.data);
              for (const [key] of Object.entries(this.data)) {
                this.data[key] = "";
              }
              this.$store.dispatch("loading", false);
              this.openModal = true;
            })
            .catch((err) => {
              new Error(err);
              this.$store.dispatch("loading", false);
              if (
                err?.response?.data?.inn &&
                err?.response?.data?.inn?.includes("already exists")
              )
                this.message = "Данный ИНН уже существует в нашей базе данных";
            });
        }
      }
    },
    editCounterparty(val) {
      const counterparty = this.counterparties.find((i) => i.id === val);
      this.id = counterparty.id;
      this.edit = true;
      for (const [key] of Object.entries(counterparty)) {
        this.data[key] = counterparty[key];
      }
    },
  },
};
</script>
