<template>
  <table class="end-customer-table" v-if="!$isMobile">
    <thead>
      <tr>
        <th>Конечный заказчик</th>
        <th class="upp">Инн</th>
        <th>Регион</th>
        <th>Город</th>
        <th>ФИО заведующего эндоскопии</th>
      </tr>
    </thead>
    <tbody v-if="endCustomers.length">
      <tr
        v-for="c in endCustomers"
        :key="c.id"
        @click="
          user.access_group !== 'manager' &&
            user.access_group !== 'director' &&
            $emit('edit', c.id)
        "
      >
        <td>
          <span>
            {{ c.name }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ c.name }}
          </span>
        </td>
        <td>
          <span>
            {{ c.inn }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ c.inn }}
          </span>
        </td>
        <td>
          <span>
            {{ c.region }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ c.region }}
          </span>
        </td>
        <td>
          <span>
            {{ c.city }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ c.city }}
          </span>
        </td>
        <td>
          <span>
            {{ c.head_of_endoscopy_full_name }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ c.head_of_endoscopy_full_name }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    class="end-customer-table-mobile table-mobile"
    ref="table"
    v-else-if="endCustomers.length && $isMobile"
  >
    <div
      class="table-mobile-column"
      v-for="c in endCustomers"
      :key="c.id"
      @click="
        user.access_group !== 'manager' &&
          user.access_group !== 'director' &&
          $emit('edit', c.id)
      "
    >
      <div class="table-mobile-row" v-if="c.name">
        <span class="end-customer-table-mobile__title table-mobile__title">
          Кон. заказчик
        </span>
        <span class="table-mobile__value">
          {{ c.name }}
        </span>
      </div>
      <div class="table-mobile-row" v-if="c.inn">
        <span class="end-customer-table-mobile__title table-mobile__title upp">
          ИНН
        </span>
        <span class="table-mobile__value">
          {{ c.inn }}
        </span>
      </div>
      <div class="table-mobile-row" v-if="c.region">
        <span class="end-customer-table-mobile__title table-mobile__title">
          Регион
        </span>
        <span class="table-mobile__value">
          {{ c.region }}
        </span>
      </div>
      <div class="table-mobile-row" v-if="c.city">
        <span class="end-customer-table-mobile__title table-mobile__title">
          Город
        </span>
        <span class="table-mobile__value">
          {{ c.city }}
        </span>
      </div>
      <div class="table-mobile-row" v-if="c.head_of_endoscopy_full_name">
        <span class="end-customer-table-mobile__title table-mobile__title">
          <span class="upp">фио</span> зав.
        </span>
        <span class="table-mobile__value">
          {{ c.head_of_endoscopy_full_name }}
        </span>
      </div>
    </div>
  </div>
  <span
    class="offer-content-notFound content-notFound"
    v-if="!endCustomers.length"
  >
    Ничего не найдено
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EndCustomersTable",
  props: {
    endCustomers: {
      type: Array,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  emits: ["edit"],
};
</script>

<style lang="scss" scoped></style>
