<template>
  <table class="requisites-table" v-if="!$isMobile">
    <thead>
      <tr>
        <th>Компания</th>
        <th class="upp">Инн</th>
        <th>Адрес</th>
        <th>Телефон</th>
        <th>Эл. почта</th>
      </tr>
    </thead>
    <tbody v-if="companies.length">
      <tr
        v-for="r in companies"
        :key="r.id"
        @click="
          user &&
            user.access_group !== 'manager' &&
            user.access_group !== 'director' &&
            $emit('edit', r.id)
        "
      >
        <td>
          <span>
            {{ r.name }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ r.name }}
          </span>
        </td>
        <td>
          <span>
            {{ r.inn }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ r.inn }}
          </span>
        </td>
        <td>
          <span>
            {{ r.address_street && r.address_street + "," }}
            {{ r.address_city && r.address_city + "," }}
            {{ r.address_country && r.address_country + "," }}
            {{ r.address_index && r.address_index }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ r.address_street && r.address_street + "," }}
            {{ r.address_city && r.address_city + "," }}
            {{ r.address_country && r.address_country + "," }}
            {{ r.address_index && r.address_index }}
          </span>
        </td>
        <td>
          <span> +{{ r.phone_number }} </span>
          <span class="tooltip" v-tooltip> +{{ r.phone_number }} </span>
        </td>
        <td>
          <span>
            {{ r.email }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ r.email }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    class="requisites-table-mobile table-mobile"
    ref="table"
    v-else-if="companies.length && $isMobile"
  >
    <div
      class="table-mobile-column"
      v-for="r in companies"
      :key="r.id"
      @click="
        user &&
          user.access_group !== 'manager' &&
          user.access_group !== 'director' &&
          $emit('edit', r.id)
      "
    >
      <div class="table-mobile-row">
        <span class="table-mobile__title requisites-table-mobile__title">
          Компания
        </span>
        <span class="table-mobile__value">
          {{ r.name }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title requisites-table-mobile__title upp">
          Инн
        </span>
        <span class="table-mobile__value">
          {{ r.inn }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title requisites-table-mobile__title">
          Адрес
        </span>
        <span class="table-mobile__value">
          {{ r.address_street && r.address_street + "," }}
          {{ r.address_city && r.address_city + "," }}
          {{ r.address_country && r.address_country + "," }}
          {{ r.address_index && r.address_index }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title requisites-table-mobile__title">
          Телефон
        </span>
        <span class="table-mobile__value">
          {{ r.phone_number }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title requisites-table-mobile__title">
          Эл. почта
        </span>
        <span class="table-mobile__value">
          {{ r.email }}
        </span>
      </div>
    </div>
  </div>
  <span
    class="offer-content-notFound content-notFound"
    v-if="!companies.length"
  >
    Ничего не найдено
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CompanyRequisitesTable",
  props: {
    companies: {
      type: Array,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  emits: ["edit"],
};
</script>
